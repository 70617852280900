<template>
  <div class="my-orders-empty-state">
    <p class="text">You do not have orders yet.</p>
  </div>
</template>

<script>
export default {
  name: 'MyOrdersEmptyState',
};
</script>

<style lang="scss" scoped>
.my-orders-empty-state {
  .text {
    padding: 15px 0 25px 0;
    color: $supplemental-text-color;
    border-bottom: 1px solid $divider-line-color;
  }
}
</style>
