<template>
  <div class="order-item">
    <div class="order-item-row">
      <a v-if="order.product_url" :href="order.product_url">{{ order.product_name }}</a>
      <div v-else>{{ order.product_name }}</div>
      <div class="supplemental">
        {{ order.product_type }}<span v-if="order.display_status"> - {{ order.display_status }}</span>
      </div>
    </div>

    <div class="order-item-row">
      <div class="supplemental datetime">{{ datetime }}</div>
      <div>
        <span>{{ order.display_cost }}</span>
        <span class="separator" v-if="order.receipt_url">|</span>
        <a v-if="order.receipt_url" :href="order.receipt_url" target="_blank">View receipt</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    order: Object,
  },
  computed: {
    datetime() {
      return moment(this.order.created_at).format('MMMM D[th], YYYY');
    },
  },
  name: 'OrderItem',
};
</script>

<style lang="scss" scoped>
.order-item {
  margin-top: 10px;
  .separator {
    margin: 0 5px;
  }
  .supplemental {
    margin: 10px 0;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    display: flex;
    justify-content: space-between;

    .order-item-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .order-item-row:first-child {
      max-width: 70%;
    }
    .datetime {
      margin: 2px 0 10px;
    }
  }
}
</style>
